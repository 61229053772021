let appBarLoaded = false;

function loadAppBar() {
  if (appBarLoaded) {
    return;
  }

  appBarLoaded = true;
  // eslint-disable-next-line no-unused-expressions
  import(/* webpackChunkName: "AppBar" */ '../components/AppBar');
  import(/* webpackChunkName: "GTMEventTracking" */ '../utils/gtmEventTracking');
}

if (typeof requestIdleCallback === "function") {
  requestIdleCallback(loadAppBar);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(loadAppBar, 1000);
}
